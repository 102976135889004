import Figure from './figure'
import BlockRenderer from './serializers/block-renderer'
import ListRenderer from './serializers/list-renderer'

const serializers = {
  types: {
    figure: Figure,
    block: BlockRenderer
  },
  list: ListRenderer
}

export default serializers
