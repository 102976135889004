import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import classNames from 'classnames'

import styles from './figure.module.css'

function FadeInSection (props) {
  const [isVisible, setVisible] = React.useState(true)
  const domRef = React.useRef()
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    observer.observe(domRef.current)
    return () => observer.unobserve(domRef.current)
  }, [])
  var cN = classNames({[styles.fadeIn]: true, [styles.isVisible]: isVisible})
  return (
    <div className={cN} ref={domRef}>
      {props.children}
    </div>
  )
}

export default ({node}) => {
  if (!node.asset) {
    return null
  }

  const fluidProps = getFluidGatsbyImage(node.asset._ref, {maxWidth: 2880}, clientConfig.sanity)

  return (
    <figure className={styles.root}>
      <FadeInSection>
        <Img fluid={fluidProps} alt={node.alt} className={styles.image} />
      </FadeInSection>
      {node.caption && <figcaption className={styles.caption}>{node.caption}</figcaption>}
    </figure>
  )
}
