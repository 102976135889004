import React from 'react'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockContent from '../components/block-content'
import Img from 'gatsby-image'

import styles from './about.module.css'

export const query = graphql`
  query aboutPage {
    person: sanityPerson(_id: {eq: "561f2d27-6441-4ce5-ba16-e8c5ba7043fc"}) {
      name
      _rawBio
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const person = (data || {}).person

  if (!person) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title='Gabriel Lovato, Senior Product Designer' />
      <Container>
        <div className={styles.root}>
          <div className={styles.picture}>
            {person.image && person.image.asset && (
              <Img
                className={styles.image}
                fluid={person.image.asset.fluid}
                alt={person.image.alt}
              />
            )}
          </div>
          <div className={styles.bio}>
            <div className={styles.title}>About</div>
            <BlockContent className={styles.text} blocks={person._rawBio} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutPage
