import React from 'react'
import BlockContent from '../block-content'
import styles from '../typography.module.css'

const BlockRenderer = props => {
  const {style = 'normal'} = props.node

  if (style === 'normal') {
    return <p className={styles.paragraph}>{props.children}</p>
  }

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    const cn = 'title' + level
    return React.createElement(style, {className: styles[cn]}, props.children)
  }

  return BlockContent.defaultSerializers.types.block(props)
}

export default BlockRenderer
